import React, { Component } from 'react';
import AdSlider from '@ctrip/adsdk';
import { addEventListener } from 'consolidated-events';
import ResizeDetector from 'react-resize-detector';

import AdSliderConfig from '../../../constants/channel/AdSlider';

import {
	UbtBoundary,
	ChannelUbtGenerator,
	CHANNEL_UBT_GROUP_TYPES,
	CHANNEL_UBT_KEY_TYPES,
} from '../../../ubt.v2/channel';
import './ad-slider.scss';

class AdSliderV2 extends Component {
	constructor(props) {
		super(props);

		this.state = {
			useCustomNav: false,
			adNoneValueClass: {
				flightAdSlider: '',
			},
			showPrev: {
				flightAdSlider: false,
			},
			showNext: {
				flightAdSlider: false,
			},
			prevBtnPosition: {},
			nextBtnPosition: {},
		};

		this.loadAd = this.loadAd.bind(this);
		this.onPrevClick = this.onPrevClick.bind(this);
		this.onNextClick = this.onNextClick.bind(this);
		this.onAdMouseEnter = this.onAdMouseEnter.bind(this);
		this.onAdMouseLeave = this.onAdMouseLeave.bind(this);
		this.onFormMouseEnter = this.onFormMouseEnter.bind(this);
		this.onFormMouseOut = this.onFormMouseOut.bind(this);
		this.onResize = this.onResize.bind(this);

		this.onResize = this.onResize.bind(this);
		this.slider = null;
		this.swiper = null;
		this.swiperSize = 0;
	}

	onResize() {
		let clientWidth = document.body.clientWidth || window.screen.width;
		if (clientWidth <= 1220) {
			this.setState({
				prevBtnPosition: { left: 0 },
				nextBtnPosition: { right: 0 },
			});
		} else {
			this.setState({
				prevBtnPosition: {},
				nextBtnPosition: {},
			});
		}
	}

	loadAd() {
		let thiz = this;
		this.slider.load({
			// mediaCode: thiz.adConfig.mediaCode || 'JPUPKC', // 业务类型
			// pageCode: thiz.adConfig.pageCode || '039GJCQZH', // 页面加载类型
			lonAndLat: { isEnable: false },
			slideVideo: {
				dotWidthAndHeight: [10, 10], //切换的小点点宽高
				dotMargin: '10', //切换的小点点间距
				bottom: 4,
				dotCurrentType: 'pencil', //dot,pencil 【注：默认选中也是原点，和没有选中一样，pencil 类似老的系统，选中的是长条圆头】
				effect: 'fade',
				// dotColor: '#fff',
				delayTime: AdSliderConfig.delayTime || 7000,
				speed: AdSliderConfig.speed || 500,
				// dotCurrentColor: '#fff',
				dotPosition: 'center',
				dotShow: !this.state.useCustomNav, //切换的小点点是否显示
			},
			adList: [
				{
					impId: AdSliderConfig.flightADSlider.impId,
					tagId: 'flightAdSlider', // HTML标签ID-必填
					width: 1180, //动态计算值，根据屏幕动态计算的【例如：屏幕是414，你的div要占屏幕宽度一半，那么你传207，假如屏幕是375,那么你们要一半则传187.5】例如可以用document.body.clientWidth||window.screen.width 之类的获取
					height: 156, //动态计算值，根据屏幕动态计算的（同上，当然，也可以固定高，左右两侧会裁剪掉）
				},
			],
			heightResizeFixed: true,
			jumpType: {
				bySelf: true,
				handleItemClick: function (url) {
					window.open(url);
				},
			},
			adLoadComplete(slider, status) {
				let searchForm = document.querySelector('#searchForm');
				addEventListener(searchForm, 'mouseover', () => {
					thiz.onFormMouseEnter();
				});
				addEventListener(searchForm, 'mouseout', () => {
					thiz.onFormMouseOut();
				});
			},
			adCallback(data, length) {
				// let size = data && data[0] && data[0].videoAd && data[0].videoAd.bannerAd.length;
				thiz.swiperSize = length;
				if (!length) {
					thiz.setState({
						adNoneValueClass: {
							flightAdSlider: 'ad-slider-none-value',
						},
					});
				}
			},
			getAdSwiper(swiper) {
				thiz.swiper = swiper;
			},
		});
	}

	onPrevClick() {
		this.swiper && this.swiper.slidePrev();
		CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK.value.process({
			tag: { type: 'adSliderPrevNextCtrl', text: '广告左右切换点击' },
		});
	}

	onNextClick() {
		this.swiper && this.swiper.slideNext();
		CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK.value.process({
			tag: { type: 'adSliderPrevNextCtrl', text: '广告左右切换点击' },
		});
	}


	onFormMouseEnter() {
		this.swiper && this.swiper.autoplay.stop();
	}

	onFormMouseOut() {
		this.swiper && this.swiper.autoplay.start();
	}

	onAdMouseEnter() {
		if (this.swiperSize) {
			let newState = {
				showPrev: { flightAdSlider: true },
				showNext: { flightAdSlider: true },
			};
			this.setState(newState);
		}
	}

	onAdMouseLeave() {
		this.setState({
			showPrev: { flightAdSlider: false },
			showNext: { flightAdSlider: false },
		});
	}

	componentDidMount() {
		this.slider = new AdSlider();
		this.loadAd();
	}

	componentWillUnmount() {
		this.setState = () => {
			
		};
		this.swiper && this.swiper.destroy();
	}

	genAd({ sliderId }) {
		let prevDisplay = this.state.showPrev[sliderId],
			nextDisplay = this.state.showNext[sliderId],
			adNoneValueClass = this.state.adNoneValueClass[sliderId],
			{ prevBtnPosition, nextBtnPosition, useCustomNav } = this.state;

		return (
			<div className="slider-wrap" onMouseEnter={this.onAdMouseEnter} onMouseLeave={this.onAdMouseLeave}>
				<ResizeDetector handleWidth skipOnMount onResize={this.onResize} />
				{useCustomNav && (
					<>
						<a
							style={{ display: prevDisplay ? 'block' : 'none', ...prevBtnPosition }}
							className="ctrl-prev"
							onClick={() => {
								this.onPrevClick(sliderId);
							}}
						>
							<i className="slider-prev-ico">&#xe602;</i>
						</a>
						<a
							style={{ display: nextDisplay ? 'block' : 'none', ...nextBtnPosition }}
							className="ctrl-next"
							onClick={() => {
								this.onNextClick(sliderId);
							}}
						>
							<i className="slider-next-ico">&#xe645;</i>
						</a>
					</>
				)}
				<UbtBoundary
					tracker={ChannelUbtGenerator(
						CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
						CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
						{ type: 'adSliderClick', text: '广告点击' }
					)}
				>
					<div
						id={sliderId}
						className="slider-v2"
						style={{
							backgroundColor: '#EEF1F6',
							backgroundImage: adNoneValueClass ? `url(${GlobalConfigs.adDefaultPicUrlSmall})` : '',
						}}
					/>
				</UbtBoundary>
			</div>
		);
	}

	render() {
		return <div>{this.genAd({ sliderId: 'flightAdSlider' })}</div>;
	}
}

export default AdSliderV2;
