import mergeConfig from '../common/mergeConfig'
import Q_CONFIG_KEYS from './qConfigKeys'

const CONSTS = {
    mediaCode: 'JPUPKC',
    pageCode: '039GJCQZH',
    impId: '03JPUPKC039GJCQZH1121',
    domestic:{
        // mediaCode: 'JPUPKC',
        // pageCode: '039GJCQZH',
        // impId: '03JPUPKC039GJCQZH1121',
        impId: '03JPUPKC039GNUYASGNKLGR'
    },
    international: {
        impId: '03JPUPKC039GJCQZHGJPTEN'
    },
    flightADSlider: {
        impId: '03JPUPKC039GNCTPXGNZXKF'
    }
}

export default mergeConfig(Q_CONFIG_KEYS.AD_SLIDER, CONSTS)
